import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import ServicePricesStyles from '../../styles/ServicesPrices.module.sass'

/**
 * Display a Pricing Table of Services
 */
const ServicePrices = () => {
  const data = useStaticQuery(posts)

  return (
    <ul className={ServicePricesStyles.list}>
      {data.allMdx.edges.map(edge => {
        return (
          <li key={edge.node.id}>
            <Link to='/contact/'>
              {edge.node.fields.image ? (
                <Img fluid={edge.node.fields.image.childImageSharp.fluid} alt={edge.node.frontmatter.title} />
              ) : null}
              <strong>{edge.node.fields.shortestTitle}</strong>
              <em><span>From</span> &euro;{edge.node.frontmatter.price}/m<sup>2</sup></em>
            </Link>
            { edge.node.frontmatter.schema ?
              <script type="application/ld+json">
                {edge.node.frontmatter.schema}
              </script>
              : null 
            }
          </li>
        )
      })}
    </ul>
  )
}

export default ServicePrices

/**
 * Create Netlify CMS Editor Component
 */
const ServicePricesEditorComponent = {
  // Internal id of the component
  id: 'service-prices',
  // Visible label
  label: 'Service Prices',
  // Pattern to identify a block as being an instance of this component
  fields: [{ name: 'hidden', label: 'Hidden', widget: 'hidden', default: '' }],
  pattern: /<ServicePrices \/>/,
  // Preview output for this component. Can either be a string or a React component
  // (component gives better render performance)
  toPreview: function(obj) {
    return <ServicePrices />
  },
  fromBlock: function() {
    return {
      hidden: '',
    }
  },
  // Function to create a text block from an instance of this component
  toBlock: function(obj) {
    return '<ServicePrices />'
  },
}

export { ServicePricesEditorComponent }

export const posts = graphql`
  {
    allMdx(
      filter: { frontmatter: { templateKey: { eq: "service" } } }
      sort: { fields: fields___shortestTitle, order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
            shortestTitle
            image {
              childImageSharp {
                fluid(maxWidth: 120) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          frontmatter {
            price
            schema
            title
          }
        }
      }
    }
  }
`
