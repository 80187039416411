import React from 'react'

/**
 * Display the default map
 *
 * @param {string} className
 */
const Map = props => {
  return (
    <div className={`map ${props.className}`}>
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9529.691437949346!2d-6.3530857!3d53.3356863!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x951d4c3906766eb1!2sLeinster%20Facility%20Services!5e0!3m2!1sen!2sie!4v1660319522308!5m2!1sen!2sie"
        width="600"
        height="450"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
      ></iframe>
    </div>
  )
}

export default Map
